import type { GetServerSidePropsContext, GetServerSidePropsResult, NextPageContext } from "next";

import { NormalizedCacheObject } from "@apollo/client";

import { ApolloContextExtend, initializeApollo, withServerSideApolloClient } from "@/apollo/client";
import { USERS_LOG_IN_URL, ACCOUNT_READY_URL } from "@/common/constants";
import { handleAdditionalServerSideProps } from "@/common/services/nextUtils";
import {
  GetSessionProfileDocument,
  GetSessionProfileQuery,
  UserLocationDocument,
  UserLocationQuery,
} from "@/graphql/types";

export const KEY_INVALID_PASSWORD = "Invalid password";
export const KEY_ACCOUNT_DOES_NOT_EXIST = "Account doesn't exist";

export const handleSessionRedirectsServerSideProps = async (
  context: NextPageContext | GetServerSidePropsContext,
) => {
  const apolloClient = initializeApollo(context.req?.headers);
  const { locale } = context;

  const ctxCopy = context as GetServerSidePropsContext;
  let referralPath = "";
  let unauthedDestination = `/${locale}${USERS_LOG_IN_URL}`;
  const {
    data: { session },
  } = await apolloClient.query<GetSessionProfileQuery>({
    query: GetSessionProfileDocument,
  });

  if (ctxCopy.resolvedUrl !== undefined) {
    referralPath = ctxCopy.resolvedUrl;
    unauthedDestination = `/${locale}${USERS_LOG_IN_URL}?referralPath=${referralPath}`;
  }

  if (!session) {
    return {
      redirect: {
        destination: unauthedDestination,
      },
    };
  } else if (session && session.user && !session.user.onboarded) {
    return {
      redirect: {
        destination: ACCOUNT_READY_URL,
      },
    };
  }

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
      session,
    },
  };
};

export type SessionContextExtend = {
  initialApolloState: NormalizedCacheObject;
  session: GetSessionProfileQuery["session"];
  ipLocation?: string;
};

export const withServerSideSessionRedirects = <
  C extends GetServerSidePropsContext & ApolloContextExtend,
  P,
>(
  handler: (
    ctx: C & SessionContextExtend,
  ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>,
  opts?: {
    redirectOnlyUnconfirmed?: boolean;
    redirectOnlyNotOnboarded?: boolean;
    redirectIfNotSubscribed?: boolean;
  },
) =>
  withServerSideApolloClient<C & SessionContextExtend, P>(async (ctx: C) => {
    const { apolloClient } = ctx;
    const {
      data: { session },
    } = await apolloClient.query<GetSessionProfileQuery>({
      query: GetSessionProfileDocument,
    });

    if (!session && !opts?.redirectOnlyUnconfirmed) {
      return {
        redirect: {
          destination: USERS_LOG_IN_URL,
          permanent: false,
        },
      };
    }

    if (session && !session.user?.confirmed) {
      return {
        redirect: {
          destination: session.user?.nonConfirmedUsersFallbackUrl || "/confirm-account",
          permanent: false,
        },
      };
    }

    if (session && !session.user?.onboarded && !opts?.redirectOnlyNotOnboarded) {
      return {
        redirect: {
          destination: ACCOUNT_READY_URL,
          permanent: false,
        },
      };
    }

    if (session && !session.user?.activeSubscription && opts?.redirectIfNotSubscribed) {
      return {
        redirect: {
          destination: "/livestreams",
          permanent: false,
        },
      };
    }

    const initialApolloState = apolloClient.cache.extract();

    (ctx as C & SessionContextExtend).initialApolloState = initialApolloState;
    (ctx as C & SessionContextExtend).session = session;

    return handleAdditionalServerSideProps(await handler(ctx as C & SessionContextExtend), {
      initialApolloState,
    });
  });

export const withServerSideCountryGuard = <
  C extends GetServerSidePropsContext & ApolloContextExtend,
  P,
>(
  handler: (
    ctx: C & SessionContextExtend,
  ) => GetServerSidePropsResult<P> | Promise<GetServerSidePropsResult<P>>,
) =>
  withServerSideApolloClient<C & SessionContextExtend, P>(async (ctx: C) => {
    const { apolloClient, locale } = ctx;
    const {
      data: { session },
    } = await apolloClient.query<GetSessionProfileQuery>({
      query: GetSessionProfileDocument,
    });

    var loc = "";

    try {
      const userLocation = await apolloClient.query<UserLocationQuery>({
        query: UserLocationDocument,
        context: {
          headers: {
            "x-forced-ip": ctx.req.headers["x-forwarded-for"] || ctx.req.headers["x-real-ip"] || "",
            "x-real-ip": ctx.req.headers["x-real-ip"] || "",
            "x-forwarded-for": ctx.req.headers["x-forwarded-for"] || "",
          },
        },
      });
      if (userLocation.data.userLocation) {
        loc = userLocation.data.userLocation;
      } else {
        if (userLocation.error) {
          loc = userLocation.error.toString();
        }
        if (userLocation.errors) {
          loc = userLocation.errors.toString();
        }
      }
    } catch {
      loc = "CATCH";
    }

    if (loc === "DE" || loc === "NL") {
      return {
        redirect: {
          destination: `/${locale}/unavailable-region`,
          permanent: false,
        },
      };
    }

    const initialApolloState = apolloClient.cache.extract();

    (ctx as C & SessionContextExtend).initialApolloState = initialApolloState;
    (ctx as C & SessionContextExtend).session = session;

    return handleAdditionalServerSideProps(await handler(ctx as C & SessionContextExtend), {
      initialApolloState,
    });
  });
