import type { GetServerSidePropsContext, NextPage } from "next";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { Container, Checkbox, CircularProgress, Box, IconButton } from "@mui/material";

import { ErrorResponse } from "@apollo/client/link/error";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import AuthPage from "@/common/components/AuthPage";
import { Button } from "@/common/components/button/Button";
import { Card } from "@/common/components/card/Card";
import { EmailField } from "@/common/components/EmailField";
import Link from "@/common/components/Link";
import { PasswordField } from "@/common/components/PasswordField";
import { useRegistrationStyles } from "@/common/components/Registration.styles";
import SEO from "@/common/components/SEO";
import Snackbar from "@/common/components/Snackbar";
import Spinner from "@/common/components/Spinner";
import { ValidatePhoneNumberDialog } from "@/settings/components/ValidatePhoneNumberDialog/ValidatePhoneNumberDialog";

import ArrowIcon from "@/common/icons/ArrowIcon";
import CheckboxSelectedIcon from "@/common/icons/checkbox/CheckboxSelectedIcon";
import CheckboxUnselectedIcon from "@/common/icons/checkbox/CheckboxUnselectedIcon";
import AppleSMButton from "@/common/icons/social-media/sm-icon-apple.svg";
import FBSMButton from "@/common/icons/social-media/sm-icon-fb.svg";
import GoogleSMButton from "@/common/icons/social-media/sm-icon-google.svg";
import TwitterSMButton from "@/common/icons/social-media/sm-icon-twitter.svg";

import { useAuthenticationStore } from "@/authentication/hooks";
import { useSocialAuthentication } from "@/authentication/hooks/use-social-authentication";
import { bindDialogState, useDialog } from "@/common/hooks";

import { KEY_ACCOUNT_DOES_NOT_EXIST, KEY_INVALID_PASSWORD } from "@/authentication/services";
import { socialMediaProvider } from "@/authentication/types";
import { USERS_REGISTER_URL, FORGOT_PASSWORD_URL } from "@/common/constants";
import { EMAIL_PATTERN } from "@/common/constants/validation";

const Login: NextPage = () => {
  const { cx, classes } = useRegistrationStyles();
  const router = useRouter();
  const { passwordUpdate } = router.query;
  const { t } = useTranslation();

  const { loginWithPopup, isWaitingRedirectResponse, setIsWaitingRedirectResponse } =
    useSocialAuthentication();

  const [rememberMeChecked, setRememberMeChecked] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [loginError, setLoginError] = useState<string>("");
  const validatePhoneNumberDialogState = useDialog();

  const {
    isUserLoggedIn,
    login,
    loginLoading: isLoading,
    successfulLogin,
  } = useAuthenticationStore();

  const handleResendCode = () => {};
  const handleValidateClose = () => {
    setIsWaitingRedirectResponse("ok");
    validatePhoneNumberDialogState.close();
  };
  const handleValidateCode = (successful: boolean) => {
    if (successful) {
      setIsWaitingRedirectResponse("ok");
      if (router.query?.referralPath) {
        router.push(router.query?.referralPath as string);
      } else {
        router.push("/");
      }
    }
  };

  useEffect(() => {
    if (isUserLoggedIn) {
      setIsWaitingRedirectResponse("ok");
      document.body.removeAttribute("style");
      router.push("/");
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (passwordUpdate) {
      setSnackbarOpen(true);
    }
  }, [passwordUpdate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
  });

  const handleSMLogin = async (provider: socialMediaProvider) => {
    const needsToSMLogin = await loginWithPopup(provider);
    if (needsToSMLogin && needsToSMLogin.redirectToSignup) {
      setIsWaitingRedirectResponse("ok");
      router.push("/social-sign-up");
      return;
    }
    if (needsToSMLogin && needsToSMLogin.nextAttempt) {
      validatePhoneNumberDialogState.open({
        isloggingIn: true,
        nextAttemptAt: needsToSMLogin.nextAttempt,
        firebaseUid: needsToSMLogin.firebaseUid,
        rememberMe: "true",
      });
    }
  };

  const onSubmitHandler = async (data: any) => {
    const response = await login({
      email: data.email,
      password: data.password,
      rememberMe: String(data.rememberMe),
    }).catch((error: ErrorResponse) => {
      if (!error.graphQLErrors) return;
      const errorMessage = error.graphQLErrors[0].message;
      if (errorMessage === "Verify OTP code") {
        if (error.graphQLErrors[0].extensions.next_attempt_at) {
          const nextAttempt = new Date(error.graphQLErrors[0].extensions.next_attempt_at);
          validatePhoneNumberDialogState.open({
            isloggingIn: true,
            email: data.email,
            password: data.password,
            rememberMe: String(data.rememberMe),
            nextAttemptAt: nextAttempt,
            firebaseUid: "",
          });
        }
      }
    });
    if (!response) return;
    if (response && response.data?.createSession?.successful) {
      document.body.removeAttribute("style");
      if (router.query?.referralPath) {
        router.push(router.query?.referralPath as string);
      } else {
        router.push("/");
      }
    } else {
      if (
        response.data?.createSession?.messages &&
        response.data?.createSession?.messages[0]?.message === KEY_INVALID_PASSWORD
      ) {
        setLoginError(t("Session.Login.errors.InvalidPassword"));
      } else if (
        response.data?.createSession?.messages &&
        response.data?.createSession?.messages[0]?.message === KEY_ACCOUNT_DOES_NOT_EXIST
      ) {
        setLoginError(t("Session.Login.errors.InvalidEmail"));
      }
      setErrorSnackbarOpen(true);
    }
  };
  const onBackButton = () => {
    router.back();
  };

  return isUserLoggedIn ? (
    <>
      <AuthPage>
        <Container maxWidth="lg">
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        </Container>
      </AuthPage>
    </>
  ) : (
    <>
      <SEO title={t("SEO.Titles.Login")} ogDescription={t("SEO.Descriptions.Login")} />
      <AuthPage
        headerAction={
          <>
            {isWaitingRedirectResponse !== "1" && (
              <div className={classes.signupHeaderAction}>
                <span>{t("Session.SignUp.DontHaveAnAccount")}</span>
                <Link href={{ pathname: USERS_REGISTER_URL, query: router.query }} passHref>
                  <Button uppercase data-cy="SignUp__register" className={classes.actionButton}>
                    {t("Session.SignUp.AppBarTitle")}
                  </Button>
                </Link>
              </div>
            )}
          </>
        }>
        <Container maxWidth="lg">
          <div>
            <IconButton
              disableRipple
              className={classes.backIconButton}
              size="small"
              onClick={onBackButton}
              data-cy="card-more-actions-button">
              <div className="icon">
                <ArrowIcon direction="left" />
              </div>
              {t("Generic.Back")}
            </IconButton>
          </div>
          <ValidatePhoneNumberDialog
            {...bindDialogState(validatePhoneNumberDialogState)}
            onResend={handleResendCode}
            onSubmit={handleValidateCode}
            onClose={handleValidateClose}
          />
          <div className={cx(classes.container, classes.loginContainer)}>
            <div className={classes.leftBoxLogin}>
              <h1 className={classes.title}>{t("Session.Login.title")}</h1>
              <p className={classes.description}>{t("Session.Login.description")}</p>
            </div>
            <div>
              {isWaitingRedirectResponse !== "1" ? (
                <>
                  <Card className={classes.cardContainer}>
                    <div className={classes.smContainer}>
                      <div className="sm_title">
                        {t("Session.SocialMediaLogin.LoginWithSocialMedia")}
                      </div>
                      <div className="sm_buttons_container">
                        <div className="sm_button" onClick={() => handleSMLogin("APPLE")}>
                          <div className="sm_icon">
                            <Image src={AppleSMButton} />
                          </div>
                          <div className="sm_label">Apple</div>
                        </div>
                        <div className="sm_button" onClick={() => handleSMLogin("GOOGLE")}>
                          <div className="sm_icon">
                            <Image src={GoogleSMButton} />
                          </div>
                          <div className="sm_label">Google</div>
                        </div>
                        <div className="sm_button" onClick={() => handleSMLogin("FACEBOOK")}>
                          <div className="sm_icon">
                            <Image src={FBSMButton} />
                          </div>
                          <div className="sm_label">Facebook</div>
                        </div>
                        <div className="sm_button" onClick={() => handleSMLogin("X")}>
                          <div className="sm_icon">
                            <Image src={TwitterSMButton} />
                          </div>
                          <div className="sm_label">Twitter</div>
                        </div>
                      </div>
                      <div className="sm_separator">
                        <hr />
                        <span>{t("Generic.or")}</span>
                      </div>
                    </div>
                    <form id="login__form" onSubmit={handleSubmit(onSubmitHandler)} noValidate>
                      <EmailField
                        {...register("email", {
                          required: true,
                          pattern: EMAIL_PATTERN,
                        })}
                        id="email"
                        label={t("Session.Form.email")}
                        placeholder={t("Session.Form.emailPlaceholder")}
                        required
                        errorMessage={
                          errors.email && t(`Session.Form.Errors.Email.${errors.email.type}`)
                        }
                      />
                      <PasswordField
                        {...register("password", { required: true })}
                        id="password"
                        label={t("Session.Form.password")}
                        placeholder={t("Session.Form.passwordPlaceholder")}
                        required
                        errorMessage={
                          errors.password &&
                          t(`Session.Form.Errors.Generic.${errors.password.type}`)
                        }
                      />
                      <Link href={FORGOT_PASSWORD_URL}>
                        <a className={classes.forgotPassword}>{t("Session.Form.forgotPassword")}</a>
                      </Link>
                      <div className={classes.actions}>
                        <div>
                          <Checkbox
                            {...register("rememberMe")}
                            disableRipple
                            checkedIcon={<CheckboxSelectedIcon />}
                            icon={<CheckboxUnselectedIcon />}
                            checked={rememberMeChecked}
                            onChange={(event) => setRememberMeChecked(event.target.checked)}
                            color="primary"
                            classes={{ root: classes.checkbox }}
                            inputProps={{ "aria-label": "secondary checkbox", id: "remember-me" }}
                          />
                          <span className={classes.rememberMeLabel}>
                            {t("Session.Login.keepMeLogged")}
                          </span>
                        </div>
                        <Button
                          data-cy="Login__submit"
                          className={classes.actionButton}
                          type="submit"
                          disabled={isLoading}>
                          {isLoading || successfulLogin ? (
                            <Spinner color="#fff" variant="small" />
                          ) : (
                            t("Session.Form.login").toUpperCase()
                          )}
                        </Button>
                      </div>
                    </form>
                  </Card>
                </>
              ) : (
                <>
                  <Card className={classes.cardContainer}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="30vh">
                      <CircularProgress size={50} />
                    </Box>
                  </Card>
                </>
              )}
            </div>
          </div>
          <Snackbar
            open={snackbarOpen}
            message={t("Session.NewPassword.PasswordUpdatedSucessfulySnackbar")}
            onClose={() => {
              setSnackbarOpen(false);
            }}
          />
          <Snackbar
            open={errorSnackbarOpen}
            message={loginError}
            onClose={() => {
              setErrorSnackbarOpen(false);
            }}
          />
        </Container>
      </AuthPage>
    </>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  if (context.query.passwordUpdate === "1") {
    /**
     * If just updated password (from /new-password) clear cookies here because some of
     * them are httpOnly and have to be deleted server-side.
     */

    const getUrlPartAfterHttps = (url: string) => {
      if (!url) return "";
      const parts = url.split("https://");
      return parts[1] || "";
    };

    const backendUrl = getUrlPartAfterHttps(process.env.NEXT_PUBLIC_BACKEND_URL || "");
    const frontendUrl = getUrlPartAfterHttps(process.env.NEXT_PUBLIC_FRONTEND_URL || "");

    const cookiesToDelete = [
      `_politic_all_api_key=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Strict; Domain=${frontendUrl}`,
      "_politic_all_key=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; HttpOnly; Domain=.politicall.com",
      `_politic_all_web_user_remember_me=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; HttpOnly; SameSite=Lax; Domain=${backendUrl}`,
    ];

    context.res.setHeader("Set-Cookie", cookiesToDelete);
  }

  return {
    props: {},
  };
}

export default Login;
