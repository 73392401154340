import React from "react";

const CheckboxUnselectedIcon = () => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Landing-Page" stroke="none" fill="none">
        <g
          id="LangingPage-Cookies-Popup---Desktop"
          transform="translate(-1012.000000, -441.000000)"
          fill="#E4E4E4">
          <g id="Cookies-Popup" transform="translate(380.000000, 226.000000)">
            <g id="Statistic-Cookies" transform="translate(24.000000, 215.000000)">
              <rect id="Active" x="608" y="0" width="24" height="24" rx="4"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckboxUnselectedIcon;
