import React, { ReactNode } from "react";

import { Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useCardStyles = makeStyles()((theme: Theme) => ({
  card: {
    background: theme.mode.background.light,
    padding: "32px 24px",
    borderRadius: "24px",
    boxSizing: "border-box",
    marginBottom: "24px",
    boxShadow: theme.mode.shadow.card,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 12px",
    },
  },
}));

interface CardProps {
  children: ReactNode;
  className?: string;
}

export const Card = ({ children, className }: CardProps) => {
  const { classes, cx } = useCardStyles();

  return <section className={cx(classes.card, className)}>{children}</section>;
};
