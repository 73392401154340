import { useRouter } from "next/router";
import { useEffect } from "react";

import { ErrorResponse } from "@apollo/client/link/error";
import app from "firebase.js";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  TwitterAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { useTranslation } from "react-i18next";

import { useLocalStorage } from "@/common/hooks";

import { useSnackbarContext } from "@/common/providers";

import { socialMediaProvider, socialSignupDataType } from "../types";
import { useAuthenticationStore } from "./use-authentication-store";

export type smLoginValidationObject = {
  nextAttempt?: Date;
  firebaseUid?: string;
  isloggingIn?: boolean;
  redirectToSignup?: boolean;
};
export const useSocialAuthentication = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { open: openSnackbar } = useSnackbarContext();

  const [, setSocialSignupData] = useLocalStorage("socialSignupData", "");
  const [isWaitingRedirectResponse, setIsWaitingRedirectResponse] = useLocalStorage(
    "isWaitingRedirectResponse",
    "false",
  );
  const { smLogin } = useAuthenticationStore();
  const auth = getAuth(app);

  const loginWithPopup = async (
    provider: socialMediaProvider,
  ): Promise<smLoginValidationObject | undefined> => {
    let authProvider: any;
    setIsWaitingRedirectResponse("1");

    switch (provider) {
      case "APPLE":
        authProvider = new OAuthProvider("apple.com");
        break;
      case "FACEBOOK":
        authProvider = new FacebookAuthProvider();
        break;
      case "GOOGLE":
        authProvider = new GoogleAuthProvider();
        break;
      case "X":
        authProvider = new TwitterAuthProvider();
        break;

      default:
        setIsWaitingRedirectResponse("no provider");
        return;
    }

    var smLoginObject: smLoginValidationObject = {};

    await signInWithPopup(auth, authProvider)
      .then(async (result) => {
        console.log("Popup result:", result);
        if (result && result.operationType === "signIn" && result.user.uid) {
          const signupDataObj = {
            email: result.user.providerData[0].email,
            dob: "",
            username: result.user.displayName,
            fuid: result.user.uid,
            profilePicUrl: result.user.photoURL,
          } as socialSignupDataType;
          setSocialSignupData(JSON.stringify(signupDataObj));
          await smLogin({ firebaseUid: result.user.uid })
            .then((data) => {
              if (data?.data?.createSmSession?.successful) {
                setIsWaitingRedirectResponse("ok");
                router.push("/");
              } else {
                setIsWaitingRedirectResponse("ok");
                router.push("/social-sign-up");
              }
            })
            .catch(async (error: ErrorResponse) => {
              setIsWaitingRedirectResponse("ok");
              console.log("errordbg:", error);
              if (!error.graphQLErrors) return;
              const errorMessage = error.graphQLErrors[0].message;
              if (errorMessage === "Invalid user") {
                smLoginObject = {
                  redirectToSignup: true,
                };
              }
              if (errorMessage === "Verify OTP code") {
                if (error.graphQLErrors[0].extensions.next_attempt_at) {
                  const nextAttempt = new Date(error.graphQLErrors[0].extensions.next_attempt_at);
                  smLoginObject = {
                    nextAttempt: nextAttempt,
                    firebaseUid: result.user.uid,
                    isloggingIn: true,
                    redirectToSignup: false,
                  };
                }
              }
            });
        }
      })
      .catch(async (error) => {
        setIsWaitingRedirectResponse("ok");
        // Handle Errors here.
        const errorMessage = error.message as string;

        if (errorMessage.includes("account-exists-with-different-credential")) {
          let providers = "";
          const email = auth.currentUser?.email;
          if (email) {
            const oAuthProviders = await fetchSignInMethodsForEmail(auth, email);
            providers = oAuthProviders.join(",");
          }
          openSnackbar({
            open: true,
            message: t("Session.Login.LoginWithSocialMedia.UseAnotherProvider", {
              listOfProviders: providers,
            }),
          });
        }
      });

    return smLoginObject;
  };

  useEffect(() => {
    /**
     * We need to do this on pageload in case
     * the user closes the social login popup
     * and reloads/navigates to another page
     * before being notified by Firebase that the
     * popup was closed.
     */
    setIsWaitingRedirectResponse("ok");
  }, []);

  return {
    loginWithPopup,
    isWaitingRedirectResponse,
    setIsWaitingRedirectResponse,
  };
};
